import React                                from 'react';
import { GiDiamondRing }                    from 'react-icons/gi';
import { HiLightBulb }                      from 'react-icons/hi';
import { Box, Button, Flex, useMediaQuery } from '@chakra-ui/react'
import { AiFillHeart }                      from 'react-icons/ai';
import { graphql }                          from 'gatsby';
import Img                                  from 'gatsby-image';

import Link                from '@interness/web-core/src/components/elements/Link/Link';
import Heading             from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer              from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper             from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import LiveAnnouncements   from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import Carousel            from '@interness/web-core/src/components/media/Carousel/Carousel';
import CallToAction        from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import FramedImage         from '@interness/theme-sonora/src/components/FramedImage';
import FurtherLinksSection from '@interness/wedding-rings-studio-addon/src/components/FurtherLinksSection';
import SEO                 from '@interness/web-core/src/components/modules/SEO/SEO';
import Mounted             from '@interness/web-core/src/components/structure/Mounted/Mounted';
import Center              from '@interness/web-core/src/components/structure/Center/Center';


const IndexPage = ({ data }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <SEO title={'Startseite'}/>
      <Mounted>
        <Carousel>
          {data[isMobile ? 'headerImagesMobile' : 'headerImages'].images.map((image) => (
            <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
                 alt={image.file.localFile.name}/>
          ))}
        </Carousel>
      </Mounted>
      <Wrapper>
        <Center>
          <Button mt="-20px" colorScheme="brand" sx={{ textDecoration: 'none' }} as={Link} to="/terminvereinbarung">Zur
            Terminvereinbarung</Button>
        </Center>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'} icon={<GiDiamondRing/>}>Trauringschmiede Wamser</Heading>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 16]}>
              <FramedImage>
                <Img fluid={data.welcome.media[0].file.localFile.childImageSharp.fluid}/>
              </FramedImage>
            </Box>
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 16]}>
              <p>Herzlich willkommen in der Trauringschmiede Wamser in Erbach!</p>
              <p>Wir freuen uns, Sie in unserer exklusiven Trauringschmiede in der Werner-von-Siemens-Str. 17 begrüßen
                zu
                dürfen.</p>
              <p>Unser Studio ist der ideale Ort, um die perfekten Ringe für den wichtigsten Tag in Ihrem Leben zu
                finden.
                Bei uns dreht sich alles um Trauringe, Eheringe, Verlobungsringe und Antragsringe. Unser Ziel ist es,
                Ihnen ein emotionales und unvergessliches Erlebnis bei der Auswahl Ihrer Ringe zu bieten.</p>
              <Button as={Link} colorScheme="brand" to="/einblicke" sx={{ textDecoration: 'none' }}>Weitere
                Einblicke</Button>
            </Box>
          </Flex>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<AiFillHeart/>}>Trauringkonfigurator</Heading>
          <Flex flexWrap="wrap" justifyContent="center">
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} external
                    to="https://konfigurator.saintmaurice.de/" m={2}>Konfigurator Saint Maurice</Button>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} external
                    to="https://konfigurator.breuning.de/home" m={2}>Konfigurator Breuning</Button>
          </Flex>
        </section>
        <Spacer />
        <section>
          <Heading tag={'h2'} icon={<HiLightBulb/>}>Wissenswertes über Trauringe</Heading>
          <FurtherLinksSection/>
        </section>
        <Spacer/>
        <CallToAction description="Vereinbaren Sie noch heute Ihren Termin zur Trauringberatung"
                      button="Zur Terminvereinbarung" link="/terminvereinbarung"
                      imageFileTitle="theme-sonora-call-to-action-background-trauringe"/>
        <Spacer/>
      </Wrapper>
    </>
  )
};

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "index"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImagesMobile: directusMediaCollection(name: {eq: "index-mobile"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        welcome: directusMediaCollection(name: {eq: "index-hero"}) {
            name
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;